<template>
  <WeContainer card="">
    <!-- Top Card -->
    <WeCard class="mb-3 sticky-top shadow-sm">
      <div class="row align-items-center">
        <div class="col-12 col-md text-center text-md-left">
          <h5 class="mb-md-0">
            <i class="fas fa-sync"></i> Toplu Ürün SEO Güncelle
          </h5>
        </div>
        <div class="col-12 col-md-auto text-center">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-bind:permission="checkPermission"
            v-on:submit="submitForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>
    <!-- ./Top Card -->

    <!-- Filter -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-filter"></i>
        <span>Filtreleme</span>
      </h5>
      <hr />
      <div class="row">
        <!-- Product -->
        <div class="col-12 col-lg-6">
          <WeRowInput
            class="mb-2"
            size="sm"
            name="product-name"
            label="Ürün Adı"
            placeholder="Ürün Adı"
            v-model="filter.name"
            v-bind:clearable="true"
          />
          <!-- Product -->

          <!-- SKU -->
          <WeRowInput
            class="mb-2"
            size="sm"
            name="sku-no"
            label="Stok Kodu"
            placeholder="Stok Kodu"
            v-model="filter.sku_no"
            v-bind:clearable="true"
          />
          <!-- SKU -->

          <!-- Brand -->
          <WeRowInput
            class="mb-2"
            form="searchSelect"
            size="sm"
            name="brand"
            label="Marka"
            placeholder="Marka"
            v-model="filter.brand"
            v-bind:multiple="true"
            v-bind:close-on-select="false"
            v-bind:clearable="true"
          />
          <!-- Brand -->

          <!-- Category -->
          <WeRowInput
            class="mb-2"
            form="searchSelect"
            size="sm"
            name="category"
            label="Kategori"
            placeholder="Kategori"
            v-model="filter.category"
            v-bind:multiple="true"
            v-bind:close-on-select="false"
            v-bind:clearable="true"
          />
          <!-- Category -->

          <!-- Created At -->
          <WeRowInput
            class="mb-2"
            form="date"
            size="sm"
            label="Eklenme Tarihi"
            placeholder="Eklenme Tarihi"
            v-bind:date-range="true"
            v-model="filter.date"
          />
          <!-- Created At -->

          <!-- Quantity -->
          <div class="d-block mb-2">
            <div class="form-row align-items-center">
              <div class="col-12 col-lg-3">
                <label for="price" class="custom-label">Stok Miktarı</label>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row align-items-center">
                  <div class="col">
                    <WeInput
                      v-bind:small-size="true"
                      class="mb-0"
                      v-bind:filter-number="true"
                      v-model="filter.quantity.min"
                      placeholder="0"
                    />
                  </div>
                  <span>-</span>
                  <div class="col">
                    <WeInput
                      v-bind:small-size="true"
                      class="mb-0"
                      v-bind:filter-number="true"
                      v-model="filter.quantity.max"
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Quantity -->

          <!-- Variant -->
          <WeRowInput
            class="mb-2"
            form="vSelect"
            size="sm"
            name="product-variant"
            label="Varyasyonlu Ürünler"
            placeholder="Varyasyonlu Ürünler"
            v-model="filter.variant"
            v-bind:option-prop="variantOptions"
            v-bind:clearable="false"
          />
          <!-- Variant -->
        </div>

        <div class="col-12 col-lg-6">
          <!-- Product With Images -->
          <WeRowInput
            class="mb-2"
            form="vSelect"
            size="sm"
            name="product-image"
            label="Resimli Ürünler"
            placeholder="Resimli Ürünler"
            v-model="filter.image"
            v-bind:option-prop="filterImageOptions"
            v-bind:clearable="false"
          />
          <!-- Product With Images -->

          <!-- Price -->
          <div class="d-block mb-2">
            <div class="form-row align-items-center">
              <div class="col-12 col-lg-3">
                <label for="price" class="custom-label">Satış Fiyatı</label>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row align-items-center">
                  <div class="col">
                    <WePriceInput
                      class="mb-0"
                      ref="minPrice"
                      placeholder="0,00"
                      v-bind:small-size="true"
                      v-model="filter.price.min"
                    />
                  </div>
                  <span>-</span>
                  <div class="col">
                    <WePriceInput
                      class="mb-0"
                      placeholder="0,00"
                      ref="maxPrice"
                      v-bind:small-size="true"
                      v-model="filter.price.max"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Price -->

          <WeRowInput
            class="mb-2"
            form="vSelect"
            size="sm"
            name="is_active"
            label="Paket Ürün"
            placeholder="Paket Ürün"
            v-model="filter.is_product_package"
            v-bind:option-prop="package_options"
            v-bind:clearable="false"
          />

          <!-- WeSeo Filter -->
          <WeSeoFilter v-model="filter" />
          <!-- WeSeo Filter -->
        </div>
      </div>

      <!-- Search button -->
      <div class="mt-3">
        <span
          v-if="!filter_submit"
          class="btn btn-sm btn-primary"
          v-on:click="onFilter"
          >Filtrele</span
        >
        <span v-else class="btn btn-sm btn-primary"
          ><i class="fas fa-spinner fa-spin"></i
        ></span>
        <span class="btn btn-danger btn-sm ml-2" v-on:click="onClear"
          >Temizle</span
        >
      </div>
      <!-- Search button -->
    </WeCard>
    <!-- ./Filter -->

    <!-- Update SEO -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-sync"></i>
        <span>Güncelleme</span>
      </h5>
      <hr />
      <WeMetaData
        class="ck-editor-seo"
        v-model="meta"
        v-bind:use-editor="true"
        v-bind:show-canonical="false"
        v-bind:mentions="getMentions"
        v-bind:show-limits="false"
        v-bind:show-robots="false"
      />
    </WeCard>
    <!-- ./Update SEO -->

    <WeCard id="product-list">
      <h5 class="custom-card-title">
        <i class="fas fa-cube"></i>
        <span>Güncellenecek Ürünler</span>
      </h5>
      <hr />
      <WeTable
        v-bind:index="false"
        v-bind:allow-sort-index="false"
        v-bind:data="bulkSeo.list"
        v-bind:columns="columns"
        v-bind:ajax="true"
        v-bind:fetchOnMount="false"
        v-bind:loading="loading"
        v-bind:table-result="tableResult"
        v-on:make-request="getProductList"
        ref="productTable"
      />
    </WeCard>
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ProductSeo",
  data() {
    return {
      loading: false,
      submit: false,
      route: "products",
      package_options: [
        {
          id: "1",
          name: "Evet",
        },
        {
          id: "0",
          name: "Hayır",
        },
      ],
      filter_submit: false,
      filter: {
        name: null,
        sku_no: null,
        brand: null,
        category: null,
        date: null,
        image: null,
        variant: null,
        quantity: {
          min: null,
          max: null,
        },
        price: {
          min: helper.setPrice(null),
          max: helper.setPrice(null),
        },
        is_active: null,
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        is_product_package: "0",
      },
      copyFilter: {},
      meta: {
        title:
          "<span class='mention' data-mention='@ProductMetaTitle'>@ProductMetaTitle</span>",
        keywords:
          "<span class='mention' data-mention='@ProductMetaKeywords'>@ProductMetaKeywords</span>",
        description:
          "<span class='mention' data-mention='@ProductMetaDescription'>@ProductMetaDescription</span>",
      },
      mentions: [
        {
          id: "@ProductName",
          name: "Ürün Adı",
        },
        {
          id: "@ProductSKU",
          name: "Stok Kodu",
        },
        {
          id: "@ProductBarcode",
          name: "Barkod",
        },
        {
          id: "@ProductBrand",
          name: "Ürün Markası",
        },
        {
          id: "@ProductCategory",
          name: "Ürün Kategorisi",
        },
        {
          id: "@ProductPrice",
          name: "Ürün Fiyatı",
        },
        {
          id: "@ProductMetaTitle",
          name: "Ürün Meta Başlık",
        },
        {
          id: "@ProductMetaKeywords",
          name: "Ürün Meta Anahtar Kelimeleri",
        },
        {
          id: "@ProductMetaDescription",
          name: "Ürün Meta Açıklama",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image", width: "5%" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean_disabled" },
      ],
      filterImageOptions: [
        {
          id: null,
          name: "Seçiniz",
        },
        {
          id: "1",
          name: "Resimli Ürünler",
        },
        {
          id: "0",
          name: "Resimsiz Ürünler",
        },
      ],
      variantOptions: [
        {
          id: null,
          name: "Seçiniz",
        },
        {
          id: "1",
          name: "Varyasonlu Ürünler",
        },
        {
          id: "0",
          name: "Varyasyonu Olmayan Ürünler",
        },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("bulkSeo", ["load", "update", "getList"]),
    getFilter() {
      let copy = this.copyFilter;

      if (!Object.keys(copy).length) {
        return;
      }

      this.setPriceFilter(copy);

      copy.date = [];

      if (this.filter.date && this.filter.date.start && this.filter.date.end) {
        Object.entries(this.filter.date).forEach(([key, value]) => {
          if (value) {
            copy.date.push(value);
          }
        });
      }

      return copy;
    },
    setPriceFilter(filter) {
      let minPrice = this.filter.price.min.unmask;
      let maxPrice = this.filter.price.max.unmask;

      filter.price.min = minPrice || null;
      filter.price.max = maxPrice || null;
    },
    onFilter(submitStatus = true) {
      this.filter_submit = submitStatus;
      this.copyFilter = helper.clone(this.filter);
      this.getProductList();
    },
    getProductList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;
      this.getList({
        route: this.route,
        ajax: true,
        table: requestData,
        filter: this.getFilter(),
        onSuccess: (result) => {
          helper.copy(result.data, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          // Show button text insted of spinner
          this.filter_submit = false;

          // Set Table Loader false
          this.loading = false;

          if (this.bulkSeo.list && this.bulkSeo.list.length) {
            // Scroll to list
            document
              .getElementById("product-list")
              .scrollIntoView({ behavior: "smooth" });
          }
        },
      });
    },
    submitForm() {
      if (!this.checkPermission) {
        permission.alert(this);
      } else {
        if (this.bulkSeo.list && this.bulkSeo.list.length) {
          this.$swal({
            title: "İşlemi Onaylıyor musunuz ?",
            html: `<b>${this.tableResult.total_items}</b> adet ürün güncellenecek. Bu işlem geri alınamaz.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "İptal",
            confirmButtonText: "Güncelle",
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              this.makeRequest();
            }
          });
        } else {
          this.$swal({
            title: "Geçersiz İşlem",
            text: "Lütfen güncellenecek ürünleri filtreleyin",
            icon: "error",
          });
        }
      }
    },
    makeRequest() {
      this.submit = true;
      this.update({
        route: this.route,
        filter: this.getFilter(),
        requestData: this.meta,
        onSuccess: (result) => {
          this.$swal({
            title: "İşlem Başarılı",
            text:"Güncelleme işlemi kuyruğa alındı tamamlandığında mail adreinize bildirim gönderilecektir.",
            icon: "success",
            confirmButtonText: "Tamam",
            onClose: () => this.getProductList(),
          });
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    onClear() {
      this.filter = {
        name: null,
        sku_no: null,
        brand: null,
        category: null,
        date: null,
        image: null,
        variant: null,
        quantity: {
          min: null,
          max: null,
        },
        price: {
          min: helper.setPrice(null),
          max: helper.setPrice(null),
        },
        is_active: null,
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        is_product_package: "0",
      };
      this.copyFilter = {};

      this.onFilter(false);
    },
  },
  computed: {
    ...mapState(["bulkSeo"]),
    checkPermission() {
      return permission.check("product", "u");
    },
    getMentions() {
      return [...this.bulkSeo.globalMentions, ...this.mentions];
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style scoped>
.sticky-top {
  z-index: 2 !important;
}
.datepicker {
  z-index: 1 !important;
}
</style>